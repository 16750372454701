







































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/main/types';
import { IInlenerSalaryTable, ISalaryTable } from '@/interfaces/salaryTable';
import { Job } from '@/interfaces/job';
import VButton from '@/components/VButton.vue';
import RegisterCard from '@/components/cards/RegisterCard.vue';
import VDropdown from '@/components/VDropdown.vue';
import VBirthdateInput from '@/components/tool/VBirthdateInput.vue';
import VHelpIcon from '@/components/VHelpIcon.vue';
import { toServerDateFormat, getCurrentDate } from '@/utilities/filters.ts';
import SubscriptionInfo from '@/interfaces/subscriptionInfo';

interface CaoOption {
    value: {
      cao_id: string;
      year_end_bonus_type: string;
      year_end_bonus_amount: string;
      inlener_cao: boolean;
    };
    text: string;
}

@Component({
  components: { VButton, RegisterCard, VDropdown, VBirthdateInput, VHelpIcon },
})
export default class CaoSelector extends Vue {

  @Getter('subscriptionInfo', { namespace: 'account' }) private subscriptionInfo!: SubscriptionInfo;
  @Getter('isLoggedIn', { namespace: 'account' }) private isLoggedIn!: boolean;
  @Getter('trialEnded', { namespace: 'account' }) private trialEnded!: boolean;
  @Getter('trialDue', { namespace: 'account' }) private trialDue!: boolean | number;
  @Action('getTrialCaos', { namespace: 'main' }) private getTrialCaos!: types.GetTrialCaosAction;
  @Action('getToolCaoNames', { namespace: 'main' }) private getToolCaoNames!: types.GetToolCaoNamesAction;
  @Action('getSalaryTables', { namespace: 'main' }) private getSalaryTables!: types.GetSalaryTablesAction;
  @Action('getInlenerSalaryTables', { namespace: 'main' })
  private getInlenerSalaryTables!: types.GetInlenerSalaryTablesAction;
  @Action('getPeriodicals', { namespace: 'main' }) private getPeriodicals!: types.GetPeriodicalsAction;
  @Action('getInlenerPeriodicals', { namespace: 'main' })
  private getInlenerPeriodicals!: types.GetInlenerPeriodicalsAction;
  @Action('submitCaoTool', { namespace: 'main' }) private submitCaoTool!: types.SubmitCaoToolAction;
  @Action('submitInlenerCaoTool', { namespace: 'main' })
  private submitInlenerCaoTool!: types.SubmitInlenerCaoToolAction;

  // Inlener cao actions
  @Action('getInlenerCaoNames', { namespace: 'main' }) private getInlenerCaoNames!: types.GetInlenerCaoNamesAction;

  private loadingSalaryTables: boolean = false;
  private loadingCaos: boolean = false;
  private loadingPeriodicals: boolean = false;
  private submitting: boolean = false;

  private loggedInLastTime = false;

  private abuNbbu: string = 'false';
  private adv: string = 'false';
  private yearEndBonus: string = 'false';
  private caoSearch = '';

  private selectedCao: null | {
      cao_id: string,
      year_end_bonus_type: string,
      year_end_bonus_amount: string,
      inlener_cao: boolean,
  } = null;
  private selectedSalaryTable: ISalaryTable | IInlenerSalaryTable | null = null;
  private selectedJob: Job = new Job();
  private selectedPayScale: string | null = null;
  private selectedWorkweek: number | null = null;
  private birthdate = '';
  private fromDate = getCurrentDate();
  private selectedPeriodical: string | null = null;

  private trialCaos: string[] = [];
  private caoOptions: CaoOption[] = [];
  private salaryTables: Array<{ value: ISalaryTable | IInlenerSalaryTable, text: string }> = [];
  private periodicals: Array<{ value: string, text: string }> = [];

  private activated(): void {
    // If loggedIn status changes, a different set of Cao's is provided by the backend, so reload them
    if (this.loggedInLastTime !== this.isLoggedIn) {
      this.loggedInLastTime = this.isLoggedIn;
      this.loadCaos();
    }
  }

  private created(): void {
    this.loggedInLastTime = this.isLoggedIn;
    this.getTrialCaos().then((response) => {
      this.trialCaos = response.caoIds;
    });
    this.loadCaos();
  }

  private resetJob() {
    this.selectedJob = new Job();
  }

  private resetInputs() {
    this.resetJob();
    this.selectedSalaryTable = null;
    this.selectedPayScale =  null;
    this.selectedWorkweek = null;
    this.selectedPeriodical = null;
  }

  private loadCaos(): void {
    this.resetInputs();
    this.loadingCaos = true;
    if (!this.isLoggedIn || this.subscriptionInfo.inlenerWebModule !== true) {
      this.getToolCaoNames().then((response) => {
        this.caoOptions = response.map((elem) => {
          return {
            value: {
              cao_id: elem.cao_id,
              year_end_bonus_type: elem.year_end_bonus_type,
              year_end_bonus_amount: elem.year_end_bonus_amount,
              inlener_cao: false,
            },
            text: elem.readable_name_year,
          };
        }).sort((a, b) => a.text > b.text ? 1 : -1);
      }).finally(() => this.loadingCaos = false);
    } else {
      Promise.all([this.getInlenerCaoNames(), this.getToolCaoNames()]).then((values) => {
        const caoOptions = values[1].map((elem) => {
          return {
            value: {
              cao_id: elem.cao_id,
              year_end_bonus_type: elem.year_end_bonus_type,
              year_end_bonus_amount: elem.year_end_bonus_amount,
              inlener_cao: false,
            },
            text: elem.readable_name_year,
          };
        }).sort((a, b) => a.text > b.text ? 1 : -1);
        const inlenerCaoOptions = values[0].map((elem) => {
          return {
            value: {
              cao_id: elem.id,
              year_end_bonus_type: elem.year_end_bonus_type,
              year_end_bonus_amount: elem.year_end_bonus_amount,
              inlener_cao: true,
            },
            text: elem.readable_name,
          };
        }).sort((a, b) => a.text > b.text ? 1 : -1);
        this.caoOptions = caoOptions.concat(inlenerCaoOptions);
      }).finally(() => this.loadingCaos = false);
    }
  }

  private loadSalaryTables(selected: any): void {
    this.resetInputs();
    this.loadingSalaryTables = true;
    // TODO: differentiate between salaryTable/inlenerSalaryTable
    if (selected.inlener_cao === true) {
      this.getInlenerSalaryTables({ inlenerCaoId: selected.cao_id, fromDate: toServerDateFormat(this.fromDate), current: true })
      .then((response) => {
        this.salaryTables = response.sort((a, b) => a.name > b.name ? 1 : -1)
        .map((inlenerSalaryTable: IInlenerSalaryTable) => {
          return { value: inlenerSalaryTable, text: inlenerSalaryTable.name };
        });
      }).finally(() => {
        this.loadingSalaryTables = false;
      });
    } else {
      this.getSalaryTables({ caoId: selected.cao_id, fromDate: toServerDateFormat(this.fromDate) })
      .then((response) => {
        this.salaryTables = response.sort((a, b) => a.name > b.name ? 1 : -1)
        .map((salaryTable: ISalaryTable) => {
          return { value: salaryTable, text: salaryTable.name };
        });
        // If the cao has just one salary table, just select it
        if (this.salaryTables.length === 1) {
          this.selectedSalaryTable = this.salaryTables[0].value;
          this.selectedWorkweek = this.selectedSalaryTable.hours_in_workweek;
          this.resetJob();
        }
      }).finally(() => {
        this.loadingSalaryTables = false;
      });
    }
  }

  get trialCaoOptions() {
    return this.caoOptions.sort((elem1, elem2) => {
      if (this.trialCaos.includes(elem1.value.cao_id)) {
        return -1;
      } else if (this.trialCaos.includes(elem2.value.cao_id)) {
        return 1;
      }
      return (elem1.text.toLowerCase() > elem2.text.toLowerCase()) ? 1 : -1;
    }).map((elem) => {
      return { value: elem.value, text: elem.text, disabled: !this.trialCaos.includes(elem.value.cao_id) };
    });
  }

  get searchedCaoList() {
    const trimmedSearchText = this.caoSearch.trim().toLowerCase();
    return this.caoOptions.filter((cao) => {
      return cao.text.toLowerCase().startsWith(trimmedSearchText);
    });
  }

  get fromDateValid() {
    const date = new Date(this.fromDate.replace( /(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'));
    const now = new Date(Date.now());
    const daysInFutureMonth = new Date(now.getFullYear(), now.getMonth() + 2, 0).getDate();
    const futureMonth = new Date(now.getFullYear(), now.getMonth() + 1, Math.min(daysInFutureMonth, now.getDate()));
    return (futureMonth >= date);
  }

  get showAdv() {
    return this.selectedSalaryTable !== null && (this.selectedSalaryTable.adv > 0 || this.workweekOptions.length > 1);
  }

  get showAbuNbbu() {
    return this.selectedSalaryTable !== null && this.selectedSalaryTable.abu_calc_available === true;
  }

  get showYearEndBonus() {
    let result = false;
    if (this.selectedCao) {
      if (
        this.selectedCao.year_end_bonus_type === 'percentage' &&
        parseFloat(this.selectedCao.year_end_bonus_amount) > 0
      ) {
        result = true;
      }
    }
    return result;
  }

  get canSubmit() {
    return (
      this.fromDateValid &&
      this.selectedCao !== null &&
      this.selectedSalaryTable !== null &&
      this.selectedPayScale !== null &&
      this.canGetPeriodicals &&
      (this.selectedPeriodical !== null || this.periodicals.length === 0)
    );
  }

  get canGetPeriodicals() {
    return (
      this.fromDateValid &&
      this.selectedWorkweek !== null && // Workweek has to be selected
      this.selectedPayScale !== null && // Payscale has to be selected
      this.birthdate !== '' && // Birthdate has to be selected and valid
      !this.loadingPeriodicals // Not loading already
    );
  }

  private submit() {
    this.submitting = true;
    const salaryTableId = this.selectedCao!.inlener_cao ?
      (this.selectedSalaryTable as IInlenerSalaryTable).salary_table_id :
      (this.selectedSalaryTable as ISalaryTable).salary_table_id;
    const params: { [key: string]: string } = {
      caoId: this.selectedCao!.cao_id.toString(),
      salaryTableId: salaryTableId.toString(),
      payscale: this.selectedPayScale!.toString(),
      birthdate: toServerDateFormat(this.birthdate),
      calcDate: toServerDateFormat(this.fromDate),
      calcAbu: !this.showAbuNbbu ? 'false' : this.abuNbbu,
      hoursInWorkweek: this.selectedWorkweek!.toString(),
    };
    if (this.selectedPeriodical !== null) {
      params.periodical = this.selectedPeriodical!.toString();
    } else {
      params.periodical = '';
    }
    if (this.selectedCao?.inlener_cao === true) {
      this.submitInlenerCaoTool(params).then((response: types.ToolResponse) => {
        this.$emit('submit', {
          inlenerCao: true,
          activePer: response.active_per,
          salaryTableName: response.salary_table_name,
          salaryTable: this.selectedSalaryTable,
          cao: response.cao,
          payscale: this.selectedPayScale,
          wagesPer: response.wages_per,
          wagesDividedBy: response.wages_divided_by,
          workweek: this.selectedWorkweek,
          job: this.selectedJob,
          birthdate: this.birthdate,
          fromDate: this.fromDate,
          adv_perc: response.adv_perc,
          salary: response.salary,
          periodical: this.selectedPeriodical,
          adv: this.adv === 'true' && this.showAdv,
          abuNbbu: this.abuNbbu === 'true' && this.showAbuNbbu,
          showYearEndBonus: this.yearEndBonus === 'true' && this.showYearEndBonus,
        });
      }).finally(() => {
        this.submitting = false;
      });
    } else {
      this.submitCaoTool(params).then((response: types.ToolResponse) => {
        this.$emit('submit', {
          inlenerCao: false,
          activePer: response.active_per,
          activePerDescription: response.active_per_description,
          wagesPer: response.wages_per,
          wagesDividedBy: response.wages_divided_by,
          salaryTableName: response.salary_table_name,
          cao: response.cao,
          salaryTable: this.selectedSalaryTable,
          payscale: this.selectedPayScale,
          workweek: this.selectedWorkweek,
          job: this.selectedJob,
          birthdate: this.birthdate,
          fromDate: this.fromDate,
          adv_perc: response.adv_perc,
          salary: response.salary,
          periodical: this.selectedPeriodical,
          adv: this.adv === 'true' && this.showAdv,
          abuNbbu: this.abuNbbu === 'true' && this.showAbuNbbu,
          showYearEndBonus: this.yearEndBonus === 'true' && this.showYearEndBonus,
        });
      }).finally(() => {
        this.submitting = false;
      });
    }
  }

  @Watch('fromDate')
  private reloadSalaryTables() {
    if (this.selectedCao !== null) {
      this.loadSalaryTables(this.selectedCao);
    }
  }

  @Watch('birthdate')
  @Watch('selectedWorkweek')
  @Watch('fromDate')
  private getPeriodicalsIfPossible() {
    if (this.canGetPeriodicals) {
      if (this.selectedCao!.inlener_cao) {
        this.loadInlenerPeriodicals((this.selectedSalaryTable! as IInlenerSalaryTable).salary_table_id,
          this.selectedPayScale!,
          toServerDateFormat(this.birthdate), this.selectedCao!.cao_id,
          this.selectedSalaryTable!.hours_in_workweek,
        );
      } else {
      this.loadPeriodicals(
        this.selectedCao!.cao_id.toString(),
        (this.selectedSalaryTable! as ISalaryTable).salary_table_id,
        this.selectedPayScale!,
        toServerDateFormat(this.birthdate),
        this.selectedSalaryTable!.hours_in_workweek,
      );
      }
    }
  }

  private loadInlenerPeriodicals(
    inlenerSalaryTableId: number, payscale: string, birthdate: string, inlenerCaoId: string, hours: number,
  ): void {
    this.loadingPeriodicals = true;
    this.selectedPeriodical = null;
    this.getInlenerPeriodicals({
      hoursInWorkweek: hours.toString(),
      inlenerCaoId,
      inlenerSalaryTableId: inlenerSalaryTableId.toString(),
      payscale,
      birthdate,
      calcDate: toServerDateFormat(this.fromDate),
    }).then((periodicalResponse) => {
      this.periodicals = periodicalResponse.periodicals.map((periodical: string) => {
          return { value: periodical.toString(), text: periodical };
      });
    }).finally(() => {
      this.loadingPeriodicals = false;
    });
  }

  private loadPeriodicals(
    caoId: string, salaryTableId: number, payscale: string, birthdate: string, hours: number,
  ): void {
    this.loadingPeriodicals = true;
    this.selectedPeriodical = null;
    this.getPeriodicals({
      caoId,
      salaryTableId: salaryTableId.toString(),
      payscale,
      birthdate,
      hoursInWorkweek: hours.toString(),
      calcDate: toServerDateFormat(this.fromDate),
    }).then((periodicalResponse) => {
      this.periodicals = periodicalResponse.periodicals.map((periodical: string) => {
          return { value: periodical.toString(), text: periodical };
      });
    }).finally(() => {
      this.loadingPeriodicals = false;
    });
  }

  get jobOptions() {
    if (this.selectedSalaryTable === null) {
      return [];
    }
    if ((this.selectedSalaryTable! as IInlenerSalaryTable).jobs) {
      return ((this.selectedSalaryTable! as IInlenerSalaryTable).jobs.sort((a, b) => a.name > b.name ? 1 : -1)
      .map((job) => {
        return { value: job, text: job.name };
      }));
    }
    return ((this.selectedSalaryTable! as ISalaryTable).jobs.sort((a, b) => a.name > b.name ? 1 : -1)
    .map((job) => {
      return { value: job, text: job.name };
    }));
  }

  get workweekOptions() {
    if (this.selectedSalaryTable === null) {
      return [];
    } else {
      return this.selectedSalaryTable!.workweeks.map((workweek: number) => {
        return { value: workweek, text: workweek.toString() };
      });
    }
  }

  get payScaleOptions() {
    return this.selectedJob.job_id !== 0 ? this.selectedJob!.payscales.sort().map((payscale: string) => {
      return { text: payscale, value: payscale };
    })  :
    (this.selectedSalaryTable !== null ? this.selectedSalaryTable!.payscales.map((payscale: string) => {
      return { text: payscale, value: payscale };
    }) : []);
  }
}

